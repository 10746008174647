@charset 'utf-8';

$headerfont: 'Comfortaa', cursive;
$contentColor: #212121;

// Top navbar
.ce-nav {
  background-color: #193D6C;
  color: #FFFFFF;
  height: 156px;

  .container-fluid {

    .row {
      width: 100%;
      text-align: left;

      button {
        padding: 10px 24px;
        border-radius: 0;
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        background-color: #193D6C;
      }
    }
  }

  h3 {
    font-family: $headerfont;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  }
}

.full-news-page {
  margin: 64px 200px 0 200px !important;
  h3 {
    font-size: 32px;
    font-family: $headerfont;
    color: $contentColor;
    line-height: 36px;
    text-align: left;
    padding: 0 0 40px 12px;
  }
  
  .news-article {
    color: $contentColor;
    text-decoration: none;
    text-align: left;
    
    h5 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-family: $headerfont;
      padding: 10px 24px 12px 36px;
    }
    .article-teaser {
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 0 24px 40px 36px;
      }
    }
  }
}

//XS Viewport----------------------------------------------
@media (max-width: 575px) {
  .full-news-page {
    margin: 64px 35px 0 35px !important;
  }
  
  .ce-nav {
    .header-col {
      max-width: 100%;
      h3 {
        text-align: center;
      }
    }
    .subscribe-col {
      text-align: center;
      margin-top: 16px;
    }
  }
}

//SM Viewport----------------------------------------------
@media (min-width:576px) and (max-width: 767px) {
  .full-news-page {
    margin: 64px 125px 0 125px !important;
  }
  
  .ce-nav {
    display: flex;
    padding-left: 60px !important;
    .row {
      justify-content: center !important;
    }
    .header-col {
      max-width: 300px;
      h3 {
        text-align: right;
      }
    }
  }
}

//MED Viewport----------------------------------------------
@media (min-width:768px) and (max-width: 991px) {
  .full-news-page {
    margin: 64px 25px 0 25px !important;
  }
  .ce-nav {
    padding-left: 16px !important;
    .header-col {
      padding-right: 0;
      max-width: 300px;
    }
    .subscribe-col {
      padding-left: 0;
    }
  }
}

//LG Viewport----------------------------------------------
@media (min-width:992px) and (max-width: 1199px) {
  .full-news-page {
    margin: 64px 50px 0 50px !important;
  }

  .ce-nav {
    padding-left: 32px !important;
    .header-col {
      max-width: 300px;
    }
  }
}


//XL Viewport -------------------------------------------
@media (min-width:1200px) and (max-width: 1439px) {
  .full-news-page {
    margin: 64px 100px 0 100px !important;
  }
  .ce-nav {
    padding-left: 72px !important;
    .header-col {
      max-width: 300px;
    }
  }
}

//XXL Viewport ---------------------------------------------
@media (min-width: 1440px) {
  .ce-nav {
    padding-left: 170px !important;
    .header-col {
      max-width: 300px;
    }
  }
}

