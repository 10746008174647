@charset 'utf-8';

.filter-sideNav {
  text-align: left;

  // .search-row {
  //   margin-bottom: 24px;
  //   .col-md-9 {
  //     padding-right: 0;
  //     input {
  //       border-radius: 0%;
  //       border: 1px solid #BDBDBD;
  //     }
  //   }
  
  //   .col-md-3 {
  //     button {
  //       border-radius: 0% ;
  //       width: 48px;
  //       height: 40px;
  //       background-color: #F9AB4F;
  //       border: none;
  //       svg {
  //         width: 16px;
  //         height: 16px;
  //         color: #FFFFFF;
  //       }
  //     }
  //   }
  // }
  // .more-filter {
  //   text-align: center;
  //   .more-filters-btn {
  //     border: none;
  //     color: #193D6C;
  //     background-color: #FFFFFF;
  //     font-size: 14px;
  //     line-height: 20px;
  //     padding: 10px 24px;
  //   }
  //   .hide-filter-btn {
  //     margin-top: 24px;
  //   }
  // }

  h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    margin-bottom: 24px;
  }

  .int-h5 {
    margin-bottom: 0;
  }

  .checkbox {
    padding: 10px 0;
    input {
      margin-right: 12px;
    }
  }

  .filter-dropdown {
    margin-bottom: 24px;
    button {
      padding: 10px 24px;
      width: 100%;
      border: 1px solid #425F84;
      background-color: #FFFFFF;
      color: #425F84;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border-radius: 0%;

    }
    .show {
      border-radius: 0% !important;
      font-size: 14px;
      overflow-y: scroll;
      max-height: 300px;
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
      border: none;
      .dropdown-item {
        padding: 4px 12px;
        transition:300ms;
        &:hover {
          background-color: #AAB5C3;
        }
        &:focus {
          background-color: #6C829E;
        }
      }
    }
  }
}

//SM Viewport----------------------------------------------
@media (max-width: 767px) {
  .filter-sideNav {
    margin-bottom: 40px;
  }
}