@charset 'utf-8';

.article-ce-nav {
  background-color: #FAFAFA;
  height: 156px;

  .nav-item {
    padding-left: 12px;
    margin-left: 53px;
    display: flex;
    align-items: center;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;

    a {
      color: #193D6C;
    }
  }
}

.single-article-page {
  background-color:   #193D6C;
  display: flex;
  justify-content: center;
  padding: 60px 35px 60px 35px;
  height: 1300px;
  .card {
    max-width: 856px;
    min-height: auto;
    text-align: left;
    .card-body {
      min-height: auto;
      padding: 60px;
      .card-title {
        font-family: 'Comfortaa';
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 12px;
      }
      .sub-title {
        margin-bottom: 40px;
        font-size: 14px;
        line-height: 20px;
        .calendar-col {
          padding-right: 15px;
          width: auto;
          .calendar-svg {
            margin-right: 12px;
            width: 16px;
            height: 16px;
          }
        }
        .tag-col {
          padding-left: 0;
          .tag-svg {
            margin-right: 12px;
            margin-left: 15px;
            width: 16px;
            height: 16px;
          }
        }
      }
      .full-article {
        p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0;
          padding: 10px 20px;
         
        }
      }
    }
  }
  img {
    max-width: 100%;
    max-height: auto;
  }
}

//XS Viewport----------------------------------------------
@media (max-width: 575px) {
  .single-article-page {
    .card-body {
      padding: 50px !important;
      .calendar-col {
        margin-bottom: 12px;
      }
    }
  }
}