@charset 'utf-8';

@import './_sidenav';
@import './_homePage';
@import './articlePage';
@import './pagination';

.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

